import React from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import Portfolio from './components/Portfolio';
import Background from './components/Background';
import theme from './components/theme';
function App() {
  return (
    <ChakraProvider theme={theme}>
       
        <Portfolio />
      
    </ChakraProvider>
  );
}

export default App;
