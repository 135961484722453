import React from 'react';
import { Box } from '@chakra-ui/react';

function FlashCard({ children, ...props }) {
  return (
    <Box
      
      shadow="lg"
      borderWidth="1px"
      borderRadius="lg"
      bgGradient="linear(to-br, white.300, black.400)"
   
      display="flex"
      flexWrap="wrap"
      //flexDirection="row"
      justifyContent="normal"   // Horizontally center the content
      alignItems="center" 
      
     


     
      {...props}  // Allows passing additional styling props
    >
      {children}  {/* Dynamic content goes here */}
    </Box>
  );
}

export default FlashCard;
