import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Textarea,
  Box,
} from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

function ContactFormModal({ isOpen, onClose }) {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // State for showing toast message
  const recaptchaRef = useRef(); // useRef to handle reCAPTCHA reset

  // Function to handle changes in form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Handle reCAPTCHA token change
  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA to submit the form.');
      return;
    }

    try {
      setLoading(true);

      // Send form data and reCAPTCHA token to the backend
      const response = await axios.post('http://localhost:2000', {
        name: formData.name,
        email: formData.email,
        message: formData.message,
        token: recaptchaToken, // reCAPTCHA token from client-side
        action: 'contactForm', // Match the reCAPTCHA action from your backend
      });

      // If reCAPTCHA success
      if (response.data.success) {
        // Reset the reCAPTCHA button without clearing the form fields
        recaptchaRef.current.reset(); 
        setRecaptchaToken(null); // Clear the token after reset

        // Show success message inside the modal
        setShowSuccessMessage(true);

        // Hide success message after 5 seconds
        setTimeout(() => {
          setShowSuccessMessage(false);
        }, 5000);
      } else {
        alert('reCAPTCHA validation failed. Please try again.');
        recaptchaRef.current.reset(); // Reset reCAPTCHA after failure
        setRecaptchaToken(null); // Clear the token after reset
      }
    } catch (error) {
      alert('Error submitting form. Please try again.');
      recaptchaRef.current.reset(); // Reset reCAPTCHA in case of an error
      setRecaptchaToken(null); // Clear the token after reset
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Contact Me</ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITE_KEY} // Use the reCAPTCHA site key from .env
              onChange={handleRecaptcha}
              
              ref={recaptchaRef} // Reference to reset reCAPTCHA after submission
            />
            <Input
              name="name"
              placeholder="Your Name"
              mb={4}
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <Input
              name="email"
              placeholder="Your Email"
              mb={4}
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <Textarea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleInputChange}
              required
            />
          </ModalBody>
          <ModalFooter>
            <Button type="submit" colorScheme="teal" mr={3} isLoading={loading}>
              Send
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </form>

        {/* Show success message inside modal below the buttons */}
        {showSuccessMessage && (
          <Box
            bg="green.100"
            color="green.700"
            textAlign="center"
            borderRadius="md"
          >
            Your message has been sent successfully!
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
}

export default ContactFormModal;
