import React from 'react'
import {
  Flex,
  Grid,
  GridItem,
  Image,
  Heading,
  Text,
  Spacer,
  Box,
} from '@chakra-ui/react'
import FlashCard from './FlashCard'
import SocialIcons from './SocialIcons'
import emil from '/home/lime/website/port/src/media/emil.jpeg'
import ntnu from '/home/lime/website/port/src/media/ntnu.png'
import uppsala from '/home/lime/website/port/src/media/uppsala.png'
const projects = [
  //header
  {
    gridItemProps: {
      colSpan: { base: 1, md: 2, lg: 4 }, // Example spans
    },
    styles: {},
    content: (
      <>
        <Heading
          //mt={2} // Reduced margin-top for tighter spacing
          //mb={2} // Reduced margin-bottom for tighter spacing
        
          
          textAlign={'left'}
          //ml="auto"
        >
          M.S.<br></br> in Computer Science <br></br> &<br></br>{' '}
          Information Engineering <br></br> from UU And NTNU
        </Heading>
        <Image
      
          src={emil}
          alt="Masters degree image"
          borderRadius="full"
          boxSize={{ base: '100px', md: '150px', lg: '180px' }}
         // mb={2}
        />
        <SocialIcons></SocialIcons>
      </>
    ),
  },
  //logos
  {
    gridItemProps: {
      colSpan: { base: 1, md: 1, lg: 1 },
    },
    styles: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    content: (
      <>
        <Image
          src={uppsala}
          boxSize={{ base: '80px', md: '110px', lg: '130' }} // Responsive image size
         
        />
        <Image
          src={ntnu}
          boxSize={{ base: '80px', md: '110px', lg: '130' }} // Responsive image size
        />
      </>
    ),
  },
  //desc
  {
    gridItemProps: {
      colSpan: { base: 1, md: 3, lg: 3 },
    },
    styles: {
      alignItems: 'start', // Center content for the first FlashCard
      textAlign: 'left',
    },
    content: (
      <>
        <Text >
          I have created ID card clones, smart contract ETH gambling websites and managed marketing agencies IT infrastructure. 
          <br></br>
          <br></br>
          Contact me with a project description and we'll discuss it further.
        </Text>
      </>
    ),
  },

  // Add more projects as needed
]

function PortfolioGrid() {
  return (
    <Grid
      templateRows={{
        base: 'auto', // Automatically adjust rows based on content
        md: 'auto',
        lg: 'auto',
      }}
      templateColumns={{
        base: 'repeat(1, auto)', // 2 columns, adjust width based on content
        md: 'repeat(3, auto)', // 3 columns, adjust width based on content
        lg: 'repeat(4, auto)', // 4 columns, adjust width based on content
      }}
      gap={{ base: 1, md: 1, lg: 1 }} // Dynamic gap size
       // Padding around grid
    >
      {projects.map((project, index) => (
        <GridItem key={index} {...project.gridItemProps}>
          <FlashCard key={index} {...project.styles}>
            {project.content}
          </FlashCard>
        </GridItem>
      ))}
    </Grid>
  )
}

export default PortfolioGrid
