import { extendTheme } from '@chakra-ui/react'
import '@fontsource/inter' // Import Inter font
import { mode } from '@chakra-ui/theme-tools'

const customTheme = extendTheme({
  components: {
    Heading: {
      baseStyle: {
        p: '16px',
        fontFamily: 'Inter',
        fontWeight: 'bold', // Global default font weight for headings
        color: 'blue.700', // Global default color for all headings
        lineHeight: '1.6', // Default line-height for headings
        fontSize: {
          // Responsive font sizes for all headings
          base: '18px',
          md: '20px',
          lg: '22px',
        },
      },
      defaultProps: {
        size: null, // Remove any default size behavior (uses baseStyle fontSize)
      },
    },
    
    Text: {
      baseStyle: {
        p: '16px',
        fontFamily: 'Inter',
        fontSize: {
          base: '14px',
          sm: '16px',
          md: '18px',
          lg: '18',
          xl: '22px',
        }, // Responsive font sizes for body text
      },
    },
  },
  styles: {
    global: (props) => ({
      html: {
        margin: {
          base: '0px', // Small margin for small screens (mobile)
          sm: '32px', // Medium margin for small screens
          md: '48px', // Larger margin for tablets
          lg: '64px', // Even larger margin for desktops
          xl: '80px', // Largest margin for large screens
        }, // Global margin for html
        padding: '0', // Reset padding if needed
        boxSizing: 'border-box', // Ensure consistent box-sizing
      },
      div: {
        padding: {base:"10px", md:"16px", lg:"20px"}, // Applies to all div elements, including Box
      },
      img: {
        padding: {base:"10px", md:"16px", lg:"20px"}, // Applies to all img elements, including Image
      },
      body: {
        fontFamily: 'Inter',
        color: mode('gray.800', 'whiteAlpha.900')(props),
        bg: mode('white', 'gray.800')(props),
        lineHeight: 'base',
        boxSizing: 'border-box',
      },

      '*::placeholder': {
        color: mode('gray.400', 'whiteAlpha.400')(props),
      },
      '*, *::before, &::after': {
        borderColor: mode('gray.200', 'whiteAlpha.300')(props),
        wordWrap: 'break-word',
      },
    }),
  },
})

export default customTheme
