import { Box, Heading, Button, useDisclosure } from '@chakra-ui/react'
import PortfolioGrid from './PortfolioGrid'
import ContactFormModal from './ContactFormModal'
import StickyFooter from './StickyFooter'

function Portfolio() {
  

  return (
    <Box>
      <Heading textDecoration={'underline'} textAlign="left">
        Software Developer - Emil Åkesson{' '}
      </Heading>

      {/* Grid of portfolio items */}
      <PortfolioGrid />
    </Box>
  )
}

export default Portfolio
