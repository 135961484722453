import React, { useState, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
} from '@chakra-ui/react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';


function WhatsAppModal({ isOpen, onClose }) {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isVerified, setIsVerified] = useState(false); // State to track reCAPTCHA verification
  const [num,setNum ] = useState(null);
  const recaptchaRef = useRef();

  // Handle reCAPTCHA token change
  const handleRecaptcha = (token) => {
    setRecaptchaToken(token);
  };

  // Handle submit after recaptcha
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaToken) {
      alert('Please complete the reCAPTCHA to view the WhatsApp number.');
      return;
    }
  
    try {
      // Send the token to your backend API for validation
      const response = await axios.post('http://localhost:2000', {
        token: recaptchaToken,
        action: 'showWhatsApp',
      });
  
      if (response.data.success) {
        // If reCAPTCHA validation is successful, show the WhatsApp number
        setIsVerified(true);
        setNum(response.data.whatsappNumber);
      } else {
        alert('reCAPTCHA verification failed. Please try again.');
      }
    } catch (error) {
      console.error('Error verifying reCAPTCHA:', error);
      alert('There was an error verifying the reCAPTCHA. Please try again.');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Verify to View WhatsApp Number</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit}>
          <ModalBody>
            {!isVerified ? (
              <>
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_SITE_KEY} // Use the reCAPTCHA site key from .env
                  onChange={handleRecaptcha}
                  
                  ref={recaptchaRef}
                />
              </>
            ) : (
              <Box
                bg="green.100"
                color="green.700"
               
                textAlign="center"
                borderRadius="md"
              >
                WhatsApp Number: {num}
              </Box>
            )}
          </ModalBody>

          {!isVerified && (
            <ModalFooter>
              <Button type="submit" colorScheme="teal" mr={3}>
                Verify
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </ModalFooter>
          )}
        </form>
      </ModalContent>
    </Modal>
  );
}

export default WhatsAppModal;
