import React from 'react';
import { Box, IconButton, Link, useDisclosure } from '@chakra-ui/react';
import { FaWhatsapp, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import ContactFormModal from './ContactFormModal';
import WhatsAppModal from './WhatsAppModal';

function SocialIcons() {
  const { isOpen: isContactOpen, onOpen: onContactOpen, onClose: onContactClose } = useDisclosure();
  const { isOpen: isWhatsAppOpen, onOpen: onWhatsAppOpen, onClose: onWhatsAppClose } = useDisclosure();

  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap={4}>
      {/* WhatsApp Icon */}
      <Link>
        <IconButton
          aria-label="WhatsApp"
          icon={<FaWhatsapp />}
          colorScheme="green"
          variant="outline"
          size="lg"
          onClick={onWhatsAppOpen}
        />
      </Link>
      <WhatsAppModal isOpen={isWhatsAppOpen} onClose={onWhatsAppClose} />

      {/* Email Icon */}
      <Link>
        <IconButton
          aria-label="Email"
          icon={<FaEnvelope />}
          colorScheme="blue"
          variant="outline"
          size="lg"
          onClick={onContactOpen}
        />
      </Link>
      <ContactFormModal isOpen={isContactOpen} onClose={onContactClose} />

      {/* LinkedIn Icon */}
      <Link href="https://www.linkedin.com/in/emil-%C3%A5kesson-46b257233/" isExternal>
        <IconButton
          aria-label="LinkedIn"
          icon={<FaLinkedin />}
          colorScheme="linkedin"
          variant="outline"
          size="lg"
        />
      </Link>
    </Box>
  );
}

export default SocialIcons;
